var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "px-2 justify-content-center" },
    [
      _c(
        "b-col",
        { staticClass: "card-content mt-5", attrs: { cols: "12", lg: "8" } },
        [
          _c("h4", { staticClass: "primary fw-bold text-center" }, [
            _vm._v("Código de activación"),
          ]),
          _c("p", { staticClass: "mt-4 fs-14", attrs: { align: "justify" } }, [
            _vm._v(
              " Hemos enviado tu código de activación al celular terminado en "
            ),
            _c("strong", [_vm._v(_vm._s(_vm.celular))]),
            _vm._v(
              ", ingréselo en el siguiente campo para continuar con " +
                _vm._s(
                  _vm.isActualizarDatos
                    ? "la actualización de tus datos "
                    : "tu registro"
                ) +
                " en la Sucursal Virtual ProgreSER. "
            ),
          ]),
          _c(
            "b-row",
            { staticClass: "pb-3" },
            [
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12", md: "6" } },
                [
                  _c("p", { staticClass: "fs-12 primary fw-bold mb-1" }, [
                    _vm._v(" Ingresa aquí el código que llega a tu celular "),
                  ]),
                  _c("i", {
                    staticClass: "las la-arrow-down icon-menu primary py-1",
                  }),
                  _c(
                    "div",
                    { staticClass: "mb-2 content-center" },
                    [
                      _c("b-form-input", {
                        staticClass: "w-input-code",
                        attrs: {
                          state: _vm.$v.codigo.$dirty
                            ? !_vm.$v.codigo.$error
                            : null,
                        },
                        on: { keypress: _vm.onlyNumber },
                        model: {
                          value: _vm.$v.codigo.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.codigo, "$model", $$v)
                          },
                          expression: "$v.codigo.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-center d-block d-md-none pb-4",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "danger",
                        disabled: _vm.reenviarCodigo,
                      },
                      on: { click: _vm.validarCodigoRegistro },
                    },
                    [
                      _c("i", { staticClass: "las la-check-circle" }),
                      _vm._v(" Ingresar "),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12", md: "6" } },
                [
                  _c("p", { staticClass: "fs-12 primary fw-bold mb-1" }, [
                    _vm._v(
                      " Solicita un nuevo código de activación " +
                        _vm._s(_vm.timer !== null ? _vm.timeContador : "") +
                        " "
                    ),
                  ]),
                  _c("i", {
                    staticClass: "las la-redo-alt icon-menu primary py-1",
                  }),
                  _c("div", { staticClass: "mb-2 content-center" }, [
                    _c("p", { staticClass: "fs-12 primary fw-bold mb-0" }, [
                      _vm._v("Celular terminado en " + _vm._s(_vm.celular)),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-center d-none d-md-block",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "danger",
                        disabled: _vm.reenviarCodigo,
                      },
                      on: { click: _vm.validarCodigo },
                    },
                    [
                      _c("i", { staticClass: "las la-check-circle" }),
                      _vm._v(" Ingresar "),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "danger",
                        disabled: !_vm.reenviarCodigo,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.generarCodigo(3)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "lar la-paper-plane" }),
                      _vm._v(" Solicitar "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }