<template>
  <b-row>
    <b-col cols="12">
      <div class="steps-progressbar py-5">
        <ul class="workflow-wrapper">
          <li v-for="(item, index) in steps" :key="index" @click="setStep(index)" :class="getClassSteps(index)">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    flowStep: { type: Number, default: 0 },
    activeStep: { type: Number, default: 0 },
    steps: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    getClassSteps(index) {
      return index == this.activeStep
        ? "active cursor-pointer"
        : index < this.activeStep
        ? "previous cursor-pointer"
        : index > this.flowStep
        ? "later"
        : "previous cursor-pointer";
    },
    setStep(val) {
      this.$emit("setStep", val);
    },
  },
};
</script>
