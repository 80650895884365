<template>
  <b-row class="px-2 justify-content-center">
    <b-col cols="12" lg="8" class="card-content mt-5">
      <h4 class="primary fw-bold text-center">Código de activación</h4>
      <p class="mt-4 fs-14" align="justify">
        Hemos enviado tu código de activación al celular terminado en <strong>{{ celular }}</strong
        >, ingréselo en el siguiente campo para continuar con
        {{ isActualizarDatos ? "la actualización de tus datos " : "tu registro" }} en la Sucursal Virtual ProgreSER.
      </p>
      <b-row class="pb-3">
        <b-col cols="12" md="6" class="text-center">
          <p class="fs-12 primary fw-bold mb-1">
            Ingresa aquí el código que llega a tu celular
          </p>
          <i class="las la-arrow-down icon-menu primary py-1"></i>
          <div class="mb-2 content-center">
            <b-form-input
              class="w-input-code"
              @keypress="onlyNumber"
              v-model="$v.codigo.$model"
              :state="$v.codigo.$dirty ? !$v.codigo.$error : null"
            />
          </div>
        </b-col>
        <b-col cols="12" md="6" class="text-center d-block d-md-none pb-4">
          <b-button variant="danger" :disabled="reenviarCodigo" @click="validarCodigoRegistro">
            <i class="las la-check-circle" /> Ingresar
          </b-button>
        </b-col>
        <b-col cols="12" md="6" class="text-center">
          <p class="fs-12 primary fw-bold mb-1">
            Solicita un nuevo código de activación {{ timer !== null ? timeContador : "" }}
          </p>
          <i class="las la-redo-alt icon-menu primary py-1"></i>
          <div class="mb-2 content-center">
            <p class="fs-12 primary fw-bold mb-0">Celular terminado en {{ celular }}</p>
          </div>
        </b-col>
        <b-col cols="12" md="6" class="text-center d-none d-md-block">
          <b-button variant="danger" :disabled="reenviarCodigo" @click="validarCodigo">
            <i class="las la-check-circle" /> Ingresar
          </b-button>
        </b-col>
        <b-col cols="12" md="6" class="text-center">
          <b-button variant="danger" :disabled="!reenviarCodigo" @click="generarCodigo(3)">
            <i class="lar la-paper-plane"></i> Solicitar
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import PublicService from "@/app/core/api/public";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";
import { required, numeric, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [mixinsEvents],
  props: { isActualizarDatos: { type: Boolean, default: false } },
  data() {
    return {
      timer: null,
      codigo: null,
      celular: null,
      reenviarCodigo: false,
      contador: this.$store.getters.envioSmsTime,
    };
  },
  computed: {
    timeContador() {
      let minute = Math.floor((this.contador / 60) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      let second = this.contador % 60;
      second = second < 10 ? "0" + second : second;

      if (minute > 0) return `en ${minute}:${second} min.`;
      else return `en ${second} seg.`;
    },
  },
  validations: {
    codigo: { required, numeric, minLength: minLength(4), maxLength: maxLength(4) },
  },
  created() {
    this.celular = this.$store.getters.dataShared.celular.substr(this.$store.getters.dataShared.celular.length - 4, 4);

    if (this.isActualizarDatos) this.generarCodigo(3);
    else this.generarCodigo(0);
  },
  methods: {
    iniciarContador() {
      if (this.contador > 0) this.contador--;
      else {
        clearInterval(this.timer);
        this.timer = null;
        this.reenviarCodigo = true;
        this.contador = this.$store.getters.envioSmsTime;
      }
    },
    generarCodigo(_estadoUsuario) {
      const { dataShared } = this.$store.getters;
      const body = {
        tipo: "SMS",
        estado: _estadoUsuario,
        cedula: this.isActualizarDatos ? dataShared.formActuDatos.cedula : dataShared.cedula,
        idSolicitud: this.isActualizarDatos ? Math.round(Math.random() * 999999) : dataShared.idSolicitud,
      };
      if (_estadoUsuario === 3) this.reenviarCodigo = false;
      this.timer = setInterval(() => this.iniciarContador(), 1000);
      if (this.isActualizarDatos) {
        const bodyAux = { ...body, celular: this.$store.getters.dataShared.celular };
        PublicService.generarCodigoActualizarDatos(bodyAux).then((response) => {
          if (response.data.estado) AlertsService.success("Actualizar Datos", response.data.mensaje);
        });
      } else {
        PublicService.generarCodigoRegistro(body).then((response) => {
          if (response.data.estado) AlertsService.success("Registro", response.data.mensaje);
        });
      }
    },
    validarCodigo() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (this.isActualizarDatos) this.validarCodigoActualizarDatos();
      else this.validarCodigoRegistro();
    },
    validarCodigoRegistro() {
      const { vigenciaHashTime, dataShared } = this.$store.getters;
      const body = {
        sms: this.codigo,
        cedula: dataShared.cedula,
        url: `${location.origin}/login`,
      };
      PublicService.validarCodigoRegistro(body).then((response) => {
        if (response.data.estado) {
          const dataModal = {
            tipo: "success",
            titulo: "¡Estás a un paso de terminar tu registro en la Sucursal Virtual ProgreSER!",
            mensaje: `Enviamos a tu correo electrónico un enlace para que actives tu cuenta, este se encontrará disponible durante ${vigenciaHashTime} minutos.`,
          };
          this.$router.push("/login");
          this.$store.commit("openModalConfirms", dataModal);
        } else AlertsService.success("Registro", response.data.mensaje);
      });
    },
    validarCodigoActualizarDatos() {
      const { isLogin, ip, vigenciaHashTime, dataShared } = this.$store.getters;
      const body = {
        ip: ip,
        codigo: this.codigo,
        navegador: window.navigator.userAgent,
        cedula: dataShared.formActuDatos.cedula,
      };
      PublicService.validarCodigoActualizarDatos(body).then((response) => {
        if (response.data.estado) {
          PublicService.actualizarDatosUsuario(dataShared.formActuDatos).then((response) => {
            AlertsService.success("Actualizar Datos", response.data.mensaje);
            const dataModal = {
              tipo: "success",
              titulo: "¡Estás a un paso de terminar tu actualización en la Sucursal Virtual ProgreSER!",
              mensaje: `Enviamos a tu correo electrónico un enlace para que actives tu cuenta, este se encontrará disponible durante ${vigenciaHashTime} minutos.`,
            };
            if (!isLogin) {
              this.$router.push("/login");
            } else {
              this.$store.commit("setUserData", {
                numeroCelular: dataShared.formActuDatos.celular,
                correoElectronico: dataShared.formActuDatos.email,
              });
              this.$router.push("/home");
            }
            this.$store.commit("openModalConfirms", dataModal);
          });
        } else AlertsService.error("Actualizar Datos", response.data.mensaje);
      });
    },
  },
};
</script>
